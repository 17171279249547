<template>
  <component
    :is="isButton ? UikitButton : 'div'"
    :class="{ 'osk-likes-count__button': isButton }"
    :loading="loading"
    @click="handleToggleLike"
  >
    <div
      :class="{
        'osk-likes-count': true,
        [`osk-likes-count_active`]: likeOpts.isLiked,
        [`osk-likes-count_reverse`]: isReverse
      }"
    >
      <div
        v-if="isShowCount"
        class="osk-likes-count__count"
      >
        {{ likeOpts.count }}
      </div>

      <UikitIcon
        class="osk-likes-count__icon"
        :name="likeOpts.isLiked ? 'HeartFilled' : 'Heart'"
        :size="iconSize ?? 'm'"
        :color="iconColor"
      />
    </div>
  </component>
</template>

<script lang="ts" setup>
import UikitButton from '@/components/uikit/Button.vue';
import { IOptions } from '~/types/analytics';

const props = defineProps<{
  likesCount?: number,
  isLiked?: boolean,
  isReverse?: boolean,
  isHideZero?: boolean,
  hideCount?: boolean,
  productId: number,
  chapter?: string
  chapterId?: string
  colorWhite?: boolean
  isButton?: boolean
  iconSize?: 's' | 'm' | 'l' | 'xl' | 'auto'
  analytics?: IOptions
}>();

const emits = defineEmits<{
  (e: 'like', productId: number): void,
  (e: 'dislike', productId: number): void,
}>();

const { t } = useI18n();
const { isAuth, openAuthDialog } = useAccountStore();
const { toggleLike } = useWishlistStore();
const { openPhoneVerifyDialog, phoneVerifyWebPopup } = usePhoneVerification();

const loading = ref(false);

const likeOpts = reactive({
  count: props.likesCount ?? 0,
  isLiked: props.isLiked ?? false,
});

const isShowCount = computed<boolean>(() => !props.hideCount && (likeOpts.count > 0 ? true : props.isHideZero !== true));
const iconColor = computed(() => {
  if (likeOpts.isLiked) return 'red';

  return props.colorWhite ? 'white' : 'black';
});

async function handleToggleLike() {
  if (!isAuth.value) {
    openAuthDialog({ chapter: 'product_like' });

    return;
  }

  if (loading.value) return;

  if (!likeOpts.isLiked && phoneVerifyWebPopup.value?.addToFavorites) {
    openPhoneVerifyDialog({
      chapter: 'addToFavorites',
      phoneText: t('uikit.user.likesCount.phoneText'),
    });
  }

  loading.value = true;

  try {
    const res = await toggleLike(props.productId, likeOpts.isLiked, props.chapter, props.chapterId, props.analytics);

    if (res) {
      if (likeOpts.isLiked) {
        likeOpts.isLiked = false;
        emits('dislike', props.productId);
      } else {
        likeOpts.isLiked = true;
        emits('like', props.productId);
      }
    }
    loading.value = false;
  } catch {
    loading.value = false;
  }
}

watch(() => props.isLiked, (value) => {
  likeOpts.isLiked = !!value;

  if (value) {
    likeOpts.count++;
  } else {
    likeOpts.count--;
  }
});

watch(() => props.likesCount, (value) => {
  likeOpts.count = value ?? 0;
});
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-likes-count {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &_reverse {
    flex-direction: row-reverse;

    .osk-likes-count__count {
      margin-right: 0;
      margin-left: $indent-xsmall;
    }
  }

  &__count {
    @include font-style($font-size-body, $line-height-body, $font-weight-medium);
    margin-right: $indent-xsmall;
  }

  &__button {
    padding: 0 12px !important;
    min-width: 46px;

    .osk-likes-count__count {
      padding-left: 4px;
    }
  }

  &__icon {
    flex-shrink: 0;
  }
}
</style>
