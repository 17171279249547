<template>
  <div
    :class="{
      'osk-user': true,
      [`osk-user_size-${size}`]: !!size
    }"
  >
    <UikitUserAvatar
      :user="user"
      :size="size"
    />

    <div class="osk-user__content">
      <div class="osk-user__content-name">
        <nuxt-link
          class="osk-user__name"
          :to="`/profile/${user.id}`"
          @click="handleUserClick(user)"
        >
          {{ user.name }}
        </nuxt-link>

        <UikitIcon
          v-if="user.isTrusted"
          name="UserVerified"
          class="osk-user__trusted"
        />
      </div>

      <div
        v-if="showDate"
        class="osk-user__type"
      >
        {{ t('uikit.user.user.registrationDate', { registrationDate: registrationDateStr }) }}
      </div>

      <div
        v-if="!hideType"
        class="osk-user__type"
      >
        {{ userType }}
      </div>

      <div
        v-if="size === 'l' && user.productsCount"
        class="osk-user__items-count"
      >
        {{ $t('uikit.user.user.productsCount', user.productsCount) }}
      </div>
    </div>

    <UikitButton
      v-if="showFollow && !isCurrentUser"
      class="osk-user__button"
      color="secondary"
      size="s"
      :outline="isFollowed"
      :loading="followLoading"
      @click="handleFollow"
    >
      {{ followedText }}
    </UikitButton>
  </div>
</template>

<script lang="ts" setup>
import { UserDTO, PublicProfileDTO } from '~/restAPI/data-contracts';

const props = withDefaults(defineProps<{
  user: UserDTO | PublicProfileDTO,
  size?: 's' | 'm' | 'l',
  showFollow?: boolean
  showDate?: boolean
  hideType?: boolean
}>(), {
  size: 's',
});

const { t } = useI18n();

const emits = defineEmits<{
  (e: 'changeFollow', value: boolean): void,
  (e: 'userClick', userId: number | string): void,
}>();

const { getUserOnOskellyTime } = useDateFormatter();
const { openPhoneVerifyDialog, phoneVerifyWebPopup } = usePhoneVerification();

const userType = computed(() => (props.user.isPro ? t('common.boutique') : t('common.privateSeller')));

const registrationDateStr = computed<string>(() => {
  if (!props.user.registrationTime) return '';

  const registerDate = new Date(props.user.registrationTime * 1000);

  return getUserOnOskellyTime(registerDate);
});

const { isAuth, account } = useAccountStore();
const {
  followLoading, isFollowed, follow, followedText,
} = useFollowUser(props.user);

const isCurrentUser = computed<boolean>(() => (isAuth.value ? account.value.id === props.user.id : false));

async function handleFollow() {
  if (!isFollowed.value && phoneVerifyWebPopup.value?.productSceneSubscribe) {
    openPhoneVerifyDialog({
      chapter: 'productSceneSubscribe',
      phoneText: t('uikit.user.user.verifyPhoneText'),
    });
  }

  await follow();
  emits('changeFollow', isFollowed.value);
}

function handleUserClick(user: UserDTO | PublicProfileDTO) {
  if (!user.id) return;

  emits('userClick', user.id);
}
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-user {
  display: flex;
  align-items: center;

  &__content {
    max-width: calc(100% - 45px);
  }

  &__content-name {
    margin-bottom: 2px;
    display: flex;
    align-items: center;
  }

  &__name {
    @include overflow-ellipsis;
    font-weight: $font-weight-semibold;
    display: inline-block;

    &:hover {
      text-decoration: underline;
    }
  }

  &__type {
    color: $grey;
  }

  &__trusted {
    margin-left: 4px;
    width: 17px;
    height: 17px;
    flex-shrink: 0;
  }

  &_size-s {
    .osk-user {
      &__name,
      &__type {
        font-size: $font-size-caption;
        line-height: $line-height-caption;
      }

      &__name {
        @media screen and (min-width: 580px) {
          font-size: $font-size-body;
          line-height: $line-height-body;
        }
      }
    }
  }

  &_size-m {
    .osk-user {
      &__type {
        font-size: $font-size-caption;
        line-height: $line-height-caption;
      }

      &__name {
        font-size: $font-size-subheading;
        line-height: $line-height-subheading;
        margin-bottom: 4px;
      }
    }
  }

  // TODO Доделать другие размеры
  // &_size-m {
  //   .osk-user {
  //     &__name {
  //       // @apply lg:text-base text-sm lg:leading-4 leading-[14px] font-bold;
  //     }

  //     &__type {
  //       // @apply lg:text-sm text-xs lg:leading-4 leading-3 mt-1;
  //     }
  //   }
  // }

  // &_size-l {
  //   .osk-user {
  //     &__name {
  //       font-size: $font-size-body;
  //       line-height: $line-height-body;

  //       @media screen and (min-width: 767px) {
  //         // @apply text-base leading-4;
  //       }
  //     }

  //     &__type,
  //     &__items-count {
  //       font-size: $font-size-caption;
  //       line-height: $line-height-caption;

  //       @media screen and (min-width: 767px) {
  //         // @apply text-sm leading-4 mt-1;
  //       }
  //     }

  //     &__items-count {
  //       color: $grey;
  //     }
  //   }
  // }

  &__button {
    width: 108px;
    height: 31px;
    line-height: 31px;
    margin-left: auto;
    font-size: 12px;
    padding: 0;
  }
}
</style>
